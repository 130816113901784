<template>
  <div class="df-flex-l df-flex-col df-flex-m-m products-options">
    <div class="df-flex-sm options__title">
      <h1>
        {{ $t('SowingView.products_options.select_products') }}
      </h1>
      <p>
        {{ $t('SowingView.products_options.description') }}
      </p>
    </div>
    <span
      v-if="!mainDiseaseId && !productSuggestions.length"
      class="df-flex-m align-center empty__products"
    >
      <v-icon color="#B89900"> mdi-alert </v-icon>
      {{ $t('SowingView.products_options.empty_state') }}
    </span>
    <div v-else class="df-flex-none df-flex-m-m df-flex-col-m">
      <div class="df-col-6 df-col-12-m products__card">
        <div
          v-for="(product, index) in selectedProducts"
          :key="product.pesticide.id"
          class="df-flex-sm align-center products-card__selected"
        >
          <div class="selected__position" :class="index == 0 ? '' : '--second'">
            {{ index + 1 }}
          </div>
          <p class="flex-grow-1">{{ product.pesticide.name.toLowerCase() }}</p>
          <v-btn
            height="16px"
            icon
            width="16px"
            @click="removeSelectedProduct(product, index)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-skeleton-loader
          v-if="loadingProductSuggestions"
          height="200px"
          type="image"
          width="auto"
        />
        <div v-else>
          <div
            v-for="(product, index) in productSuggestions"
            :key="product.pesticide.id"
            class="products-card__item"
            @click="selectProduct(product, index)"
          >
            <p>{{ product.pesticide.name.toLowerCase() }}</p>
          </div>
        </div>
      </div>
      <product-mixture-information
        class="df-col-6 df-col-12-m"
        :mixture="mixture"
        @set-products="setProducts"
      />
    </div>
  </div>
</template>

<script>
import ProductMixtureInformation from '@/modules/sowingView/components/modal/ProductMixtureInformation.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'

export default {
  name: 'ProductsOptions',

  components: {
    ProductMixtureInformation,
  },

  props: {
    mainDiseaseId: {
      default: null,
      type: Number,
    },
    secondaryDiseaseId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      loadingProductSuggestions: false,
      mixture: { pesticides: [], efficacies: [] },
      productSuggestions: [],
      selectedProducts: [],
    }
  },

  created() {
    if (this.mainDiseaseId) {
      this.fetchProductSuggestions()
    }
  },

  methods: {
    async fetchProductSuggestions() {
      this.loadingProductSuggestions = true
      const params = {
        main_disease_id: this.mainDiseaseId,
        secondary_disease_id: this.secondaryDiseaseId,
      }
      if (this.selectedProducts[0]) {
        params.main_defensive_id = this.selectedProducts[0].pesticide.id
      }
      try {
        const { data } = await sowingViewService.getProductSuggestions(params)
        this.productSuggestions = data
        if (this.selectedProducts.length === 2) {
          this.setMixture()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingProductSuggestions = false
      }
    },
    removeSelectedProduct(product, index) {
      if (index === 0) {
        this.selectedProducts = []
        this.fetchProductSuggestions()
      } else {
        this.selectedProducts.splice(index, 1)
        this.productSuggestions.push(product)
        this.productSuggestions.sort((product1, product2) =>
          product1.pesticide.name.localeCompare(product2.pesticide.name)
        )
      }
      this.setMixture()
    },
    selectProduct(product, index) {
      if (this.selectedProducts.length < 2) {
        this.selectedProducts.push(product)
        this.setMixture()

        if (this.selectedProducts.length === 1) {
          this.fetchProductSuggestions()
        } else if (this.selectedProducts.length === 2) {
          this.productSuggestions.splice(index, 1)
        }
      }
    },
    setMixture() {
      if (!this.selectedProducts.length) {
        this.mixture = {
          pesticides: [],
          efficacies: [],
        }
      } else if (this.selectedProducts.length === 1) {
        this.mixture = {
          pesticides: [
            {
              id: this.selectedProducts[0].pesticide.id,
              name: this.selectedProducts[0].pesticide.name,
              active_ingredients:
                this.selectedProducts[0].pesticide.active_ingredients,
            },
          ],
          efficacies: this.selectedProducts[0].efficacies,
        }
      } else if (this.selectedProducts.length === 2) {
        this.mixture = {
          pesticides: this.selectedProducts.map((product) => {
            return {
              id: product.pesticide.id,
              name: product.pesticide.name,
              active_ingredients: product.pesticide.active_ingredients,
            }
          }),
          efficacies: this.selectedProducts[1].efficacies,
        }
      }
    },
    setProducts() {
      this.$emit('set-products', this.selectedProducts)
    },
  },
}
</script>

<style lang="scss" scoped>
.products-options {
  .options__title {
    h1 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .products__card {
    max-height: 500px;
    height: fit-content;
    overflow-y: auto;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e6e9e6;

    p {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
    }
    .products-card__selected {
      background-color: #f8f9f8;
      padding: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e9e6;
      }
      .selected__position {
        color: #fff;
        text-align: center;
        font-family: 'Rubik';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        border-radius: 9999px;
        color: #ffffff;
        height: 16px;
        width: 16px;
        background-color: #39af49;

        &.--second {
          background-color: #1a2b46;
        }
      }
    }
    .products-card__item {
      padding: 16px;
      cursor: pointer;

      &:hover {
        background-color: #e6e9e6;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #e6e9e6;
      }
    }
  }
  .empty__products {
    border-radius: 4px;
    border: 1px solid #e6e9e6;
    padding: 12px;
    background-color: #fff2b0;

    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
