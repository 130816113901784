<template>
  <div class="df-col-6 product-mixture-information">
    <div class="df-flex-m align-center description-card__title">
      <df-chip v-if="mixture.pesticides.length" :type="chipType">
        {{ tagName }}
      </df-chip>
      <h2 v-if="mixture.pesticides.length">{{ productNames }}</h2>
      <h2 v-else>
        {{ $t('SowingView.product_mixture_information.products') }}
      </h2>
    </div>
    <div
      v-if="mixture.pesticides.length"
      class="df-flex-l df-flex-col description-card__mixture"
    >
      <div class="mixture__efficiency">
        <span>
          {{ $t('SowingView.product_mixture_information.description') }}
        </span>
      </div>
      <div class="df-flex-l df-flex-col-m flex-nowrap">
        <div class="df-flex-l df-flex-col mixture__graph">
          <h3>
            {{
              $t('SowingView.product_mixture_information.potential_efficacy')
            }}
          </h3>
          <efficacy-chart :efficacies="efficacies" />
        </div>
        <v-divider class="mixture-vertical__divider" vertical />
        <v-divider class="mixture-horizontal__divider" />
        <div class="df-flex-l df-flex-col mixture__ingredients">
          <h3>
            {{
              $t('SowingView.product_mixture_information.active_ingredients')
            }}
          </h3>
          <div
            v-for="product in mixture.pesticides"
            :key="product.id"
            class="df-flex-xm df-flex-col ingredient"
          >
            <h4>{{ product.name.toLowerCase() }}</h4>
            <p>
              {{ activeIngredients(product.active_ingredients) }}
            </p>
          </div>
        </div>
      </div>
      <div class="df-flex-none mixture__button">
        <df-button @click="setProducts">
          {{ $t('SowingView.product_mixture_information.select_products') }}
        </df-button>
      </div>
    </div>
    <div
      v-else
      class="df-flex-l df-flex-col justify-center align-center mixture__empty-state"
    >
      <div class="empty-state__icon">
        <font-awesome-icon color="#5B6459" icon="spray-can" />
      </div>
      <p>
        {{ $t('SowingView.product_mixture_information.empty_state') }}
      </p>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfChip from '@/lib/components/Chip/DfChip.vue'
import '@/components/Efficacies/EfficacyChart.js'

export default {
  name: 'ProductMixtureInformation',

  components: {
    DfButton,
    DfChip,
  },

  props: {
    mixture: {
      required: true,
      type: Object,
    },
  },

  computed: {
    chipType() {
      if (this.mixture.pesticides.length === 1) return 'primary'
      return 'secondary'
    },
    efficacies() {
      let translatedEfficacies = [...this.mixture.efficacies]
      translatedEfficacies = translatedEfficacies.map((efficacy) => {
        return {
          disease_name: this.$t(`diseases.${efficacy.disease_name}`),
          efficacy: efficacy.efficacy.toFixed(2),
        }
      })
      return JSON.stringify(translatedEfficacies)
    },
    productNames() {
      const productNames = this.mixture.pesticides.reduce((acc, product) => {
        acc.push(product.name.toLowerCase())
        return acc
      }, [])
      return productNames.join(' + ')
    },
    tagName() {
      if (this.mixture.pesticides.length === 1)
        return this.$t('SowingView.product_mixture_information.single')
      return this.$t('SowingView.product_mixture_information.mixture')
    },
  },

  methods: {
    activeIngredients(ingredients) {
      const ingredientNames = ingredients.reduce((acc, ingredient) => {
        acc.push(ingredient.name)
        return acc
      }, [])
      return ingredientNames.join(', ')
    },
    setProducts() {
      this.$emit('set-products')
    },
  },
}
</script>

<style lang="scss" scoped>
.product-mixture-information {
  max-height: fit-content;
  border-radius: 0px 4px 4px 4px;
  border: 1px solid #e6e9e6;

  .description-card__title {
    border-bottom: 1px solid #e6e9e6;
    padding: 16px;

    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
  .description-card__mixture {
    padding: 12px 16px;

    .mixture__efficiency {
      background-color: #f8f9f8;
      padding: 16px;
      border-radius: 4px;

      span {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
    h3 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    .mixture__graph {
      width: 100%;
    }
    .mixture-vertical__divider {
      @include d(m) {
        display: none;
      }
    }
    .mixture-horizontal__divider {
      display: none;

      @include d(m) {
        display: block;
      }
    }
    .mixture__button {
      justify-content: end;

      .v-btn {
        @include d(m) {
          width: 100%;
        }
      }
    }
    .mixture__ingredients {
      width: 100%;

      .ingredient {
        max-height: fit-content;

        h4 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-transform: capitalize;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
  .mixture__empty-state {
    min-height: 250px;
    padding: 12px 16px;

    .empty-state__icon {
      border-radius: 999999px;
      background-color: #f8f9f8;
      height: 52px;
      width: 52px;
      position: relative;

      & svg {
        position: absolute;
        top: 18px;
        left: 18px;
      }
    }
    p {
      color: #5b6459;
      text-align: center;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
